import React, { useEffect, useCallback, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { IDataTableRow } from "../../../../components/DataTable/DataTableBody/DataTableBody";
import useAppContext from "../../../../context/hooks/useAppContext";
import { DelegatedParkingProductModel, VehicleAssignment, VehicleAssignmentProduct } from "../../../../models/delegations/DelegatedParkingProductsModel";
import useFleetManagerContext from "../../../../context/hooks/useFleetManagerContext";
import { ForFleetManager } from "../../../../models/ForFleetManager";
import { WithFiltersAndPaging } from "../../../../models/filters/WithFiltersAndPaging";
import {
  DataTableContextFilters,
  useDataTableContext,
} from "../../../../components/DataTable/DataTableContext/DataTableContextProvider";
import { FmpFilters } from "../../../../constants/filtering.constants";
import { FilterType } from "../../../../components/DataTable/DataTableFilters/DataTableFilters";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { isNil } from "lodash";
import DataTable from "../../../../components/DataTable/DataTable";
import {
  DeleteDelegeeIcon,
  DeleteDelegeeMenuItem,
  EditIcon,
  NoPaddingGrid,
} from "../../FleetManagerDataTable/StyledComponents";
import { Column } from "../../../../components/DataTable/DataTableHead/DataTableHead";
import { MenuItem } from "@material-ui/core";
import parkingProductsActions from "../../../../store/actions/parkingProducts.actions";
import { NumberPlate } from "../../MyProducts/ParkingRightsDataTable/common";
import filteredColumnObjectBuilder from "../../MyProducts/ObjectBuilders/FilteredColumnObjectBuilder";
import useMyFleetContext from "../../../../context/hooks/useMyFleetContext";
import { toProductsJsx } from "../../Helpers/ParkingRightsHelpers";
import { AssignedVehicleRow } from "../../MyProducts/ParkingRightsDataTable/VehiclesTable";
import PersonIcon from "@material-ui/icons/Person";
import VehicleIcon from "../../../../ui/Icons/Vehicle";
import { VehicleAssignments } from "../../../../constants/vehicleAssignment.constants";
import { getParkingProductOptions } from "../../../../helpers/selectOptionHelper";

export interface VehicleAssignmentRow extends IDataTableRow {
  numberPlate: JSX.Element;
  description?: string;
  products: JSX.Element | null;
  assignedDate: string;
}
type VehicleAssignmentsTableProps = Readonly<{
  onAssignVehicleClicked: () => void;
  onInviteFleetMemberClicked: () => void;
  onRevokeClicked: () => void;
  onDeleteClicked: () => void;
  subHeading?: ReactNode;
}>

const toDelegatedParkingProductModel = (products: VehicleAssignmentProduct[]) : DelegatedParkingProductModel[] => 
  products.map(p =>{ return { parkingRightId: p.parkingRightId, location: p.locationName, name:p.pmcName, pmcId: p.pmcId, created: null!, id: null!, status: null!}});


const _createData = (vehicleAssignment: VehicleAssignment): VehicleAssignmentRow => {
  return {
    rowKey: `${vehicleAssignment.vehicleAssignmentId}${VehicleAssignments.KEY_SEPARATOR}${vehicleAssignment.products.map(p => p.pmcId).join(VehicleAssignments.KEY_SEPARATOR)}`,
    numberPlate: (
      <NumberPlate countryCode={vehicleAssignment.vehicle.countryCode} identifier={vehicleAssignment.vehicle.identifier} />
    ),
    description: vehicleAssignment.vehicle.description,
    products: toProductsJsx(toDelegatedParkingProductModel(vehicleAssignment.products)),
    assignedDate: moment(vehicleAssignment.assignedAt).format("DD/MM/YYYY"),
  };
};

type MenuItemsProps = Readonly<{
  onDelete: () => void;
  onEdit: () => void;
}>;

function MenuItems(props: MenuItemsProps) {
  const { t } = useTranslation(["fleetManagerTable", "myFleet"]);

  return (
    <>
      <MenuItem key="edit" onClick={() => props.onEdit()}>
        <EditIcon />
        {t("contextMenuActions.editVehicle")}
      </MenuItem>
      <DeleteDelegeeMenuItem key="delete" onClick={() => props.onDelete()}>
        <DeleteDelegeeIcon />
        {t("contextMenuActions.deleteVehicle")}
      </DeleteDelegeeMenuItem>
    </>
  );
}

function VehiclesTableMyFleet(props: VehicleAssignmentsTableProps) {
  const dispatch = useAppDispatch();
  const vehicleAssignments = useAppSelector((state) => state.parkingProducts.vehicleAssignmentsMyFleet);
  const parkingProducts = useAppSelector((state) => state.parkingProducts.parkingProducts);

  const getVehicleAssignmentsMyFleet = useCallback(
    (request) => {
      dispatch(parkingProductsActions.getVehicleAssignmentsMyFleet(request) as any);
    },
    [dispatch]
  );
  const { t } = useTranslation(["myProducts", "globals", "dataTable", "fleetManagerTable"]);

  const { myFleetContextState, setSorting } = useMyFleetContext();
  const {
    fleetManagerState,
    setSelectedParkingRightIds,
    toggleEditVehicleAssignment,
    toggleDeleteVehicleAssignmentsWarning
  } = useFleetManagerContext();

  const dataTableContext = useDataTableContext();

  const { appState } = useAppContext();

  const getFilters = (): DataTableContextFilters[] => [    
    {
      key: FmpFilters.sortBy,
      type: FilterType.QueryParam,
      value: myFleetContextState.sortBy,
    },
    {
      key: FmpFilters.orderDesc,
      type: FilterType.QueryParam,
      value: myFleetContextState.orderDesc?.toString(),
    },
    ...(dataTableContext.state.filters as DataTableContextFilters[]),
  ];

  const getRequestModel = (): WithFiltersAndPaging<ForFleetManager> => {
    const request: WithFiltersAndPaging<ForFleetManager> = {
      entity: {
        seasonTicketOwnerCrmId: appState.user.seasonTicketOwnerCrmId as string,
        language: appState.selectedLanguage,
      },
      pagination: {
        pageSize: dataTableContext.state.pageSize!,
        pageNumber: dataTableContext.state.pageNumber!,
      },
      filters: getFilters(),
    };
    return request;
  };

  const fetchVehicleAssignments = () => {
    if (appState.user.seasonTicketOwnerCrmId) {
      getVehicleAssignmentsMyFleet(getRequestModel());
    }
  };

  const memoizedFetch = useCallback(
    (): void => fetchVehicleAssignments(), // eslint-disable-next-line
    [
      dataTableContext.state.pageSize,
      dataTableContext.state.pageNumber,
      myFleetContextState.orderDesc,
      myFleetContextState.sortBy,
      appState.user.seasonTicketOwnerCrmId,
      appState.selectedLanguage,
    ]
  );

  useEffect(() => {
    memoizedFetch();
  }, [memoizedFetch]);

  const columns: Column<VehicleAssignmentRow>[] = filteredColumnObjectBuilder.buildVehiclesColumns(
    t,
    getParkingProductOptions(parkingProducts?.data ?? []),
  );

  const onSort = (event: React.MouseEvent<unknown>, property: keyof VehicleAssignmentRow) => {
    setSorting(property.toString());
  };

  const getTableRows = (): VehicleAssignmentRow[] => {
    const { pages } = vehicleAssignments;
    if (isNil(pages)) {
      return [];
    }
    const pageData = pages[dataTableContext.state.pageNumber!]?.data;
    if (isNil(pageData)) {
      return [];
    }    
    return pageData.map(_createData);
  };

  const isLoading = vehicleAssignments.pages?.[dataTableContext.state.pageNumber!]?.loading ?? false;
  const rows = getTableRows();

  const toAssignedVehicleRow = (row: VehicleAssignmentRow) : AssignedVehicleRow => {
    return {
      assignedDate: row.assignedDate,
      numberPlate: row.numberPlate,
      description: row.description,
      disabled: row.disabled,
      product: null!,
      rowKey: row.rowKey
    }
  }

  const handleEdit = (row: VehicleAssignmentRow | null) => {
    if (isNil(row)) {
      return;
    }
    toggleEditVehicleAssignment(toAssignedVehicleRow(row));
  };

  const handleDelete = (row: VehicleAssignmentRow | null) => { 
    if (isNil(row)) {      
      return;
    }    
    toggleDeleteVehicleAssignmentsWarning(toAssignedVehicleRow(row));
  };

  return (
    <NoPaddingGrid container item direction="row" xs={12}>
      <NoPaddingGrid container item direction="column" xs={12}>
        <DataTable
          rows={rows}
          columns={columns}
          loading={isLoading}
          orderBy={myFleetContextState.sortBy ?? ""}
          pages={vehicleAssignments.totalPages}
          onFilterClicked={fetchVehicleAssignments}
          onResetFiltersClicked={fetchVehicleAssignments}
          onRequestSort={onSort}
          translationOverrideKey={"vehicles"}
          selectedRowKeys={fleetManagerState.selectedRowKeys}
          setSelectedRowKeys={setSelectedParkingRightIds}
          renderActionMenu={(focusedRow, onClose) => (
            <MenuItems
              onDelete={() => handleDelete(focusedRow)}
              onEdit={() => handleEdit(focusedRow)}
            />
          )}
          order={myFleetContextState.orderDesc ? "desc" : "asc"}
          heading={{
            options: {
              useHeading: true,
              showHeadingCount: false,
            },
            title: t("myFleet:myFleet"),
          }}
          filtering={{
            options: {
              useFilters: true,
            },
          }}
          actions={{
            options: {
              useActions: true,
            },
            revoke: {
              useRevoke: true,
              showRevoke: fleetManagerState.selectedRowKeys.length > 0,
              revokeLabel: `${t("globals:revoke")} (${fleetManagerState.selectedRowKeys.length})`,
              onRevokeClicked: props.onRevokeClicked,
            },
            delete: {
              useDelete: true,
              showDelete: fleetManagerState.selectedRowKeys.length > 0,
              deleteLabel: `${t("globals:delete")} (${fleetManagerState.selectedRowKeys.length})`,
              onDeleteClicked: props.onDeleteClicked,
            },
            menu: {
              useMenu: true,
              showMenu: true,
              menuItems: [
                {
                  label: t("fleetManagerTable:inviteFleetMember"),
                  action: props.onInviteFleetMemberClicked,
                  icon: <PersonIcon/>
                },
                {
                  label: t("fleetManagerTable:assignVehicle"),
                  action: props.onAssignVehicleClicked,
                  icon: <VehicleIcon/>
                }
              ]
            }          }}
          subHeading={props.subHeading}
        />
      </NoPaddingGrid>
    </NoPaddingGrid>
  );
}

export default VehiclesTableMyFleet;
