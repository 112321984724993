import React, { createContext, useReducer } from "react";
import { myFleetContextTypes } from "./store/actions/myFleet.types";
import { MyFleetContextDataGridState } from "./store/reducers/myFleet.reducer";
import myFleetReducer, { initialState } from "./store/reducers/myFleet.reducer";

type MyFleetContextType = {
  myFleetContextState: MyFleetContextDataGridState;
  setSorting: (sortBy: string, descending?: boolean) => void;
};

export const MyFleetContext = createContext<MyFleetContextType | null>(null);

const MyFleetContextProvider: React.FC = ({ children }) => {
  const [ myFleetContextState, dispatch ] = useReducer(myFleetReducer, initialState);

  const setSorting = (sortBy: string, descending?: boolean) => {
    dispatch({
      type: myFleetContextTypes.SET_SORTING,
      payload: { by: sortBy, descending },
    });
  };

  const store: MyFleetContextType = {
    myFleetContextState,
    setSorting,
  };

  return <MyFleetContext.Provider value={store}>{children}</MyFleetContext.Provider>;
};

export default MyFleetContextProvider;
