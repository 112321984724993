import { useContext } from "react";
import { MyProductsContext } from "../MyProductsContextProvider";

const useMyProductsContext = () => {
    const myProductsContext = useContext(MyProductsContext);

    if (myProductsContext === null) {
        throw new Error("You forgot to use MyProductsContextProvider");
    }

    return myProductsContext;
};

export default useMyProductsContext;
