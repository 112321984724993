import { MyFleetContextAction, myFleetContextTypes } from "../actions/myFleet.types";

export type MyFleetContextDataGridState = {
  sortBy?: string;
  orderDesc?: boolean;
};

export const initialState: MyFleetContextDataGridState = {
  sortBy: "invitationDate",
  orderDesc: true,
};

export default (
  state: MyFleetContextDataGridState = initialState,
  action: MyFleetContextAction
): MyFleetContextDataGridState => {
  switch (action.type) {
    case myFleetContextTypes.SET_SORTING: {
      const payloadDesc = action.payload.descending;
      return {
        ...state,
        sortBy: action.payload.by,
        orderDesc: typeof payloadDesc === "boolean" ? payloadDesc : !state.orderDesc,
      };
    }

    default:
      return state;
  }
};
