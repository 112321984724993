import { useContext } from "react";
import { MyFleetContext } from "../MyFleetContextProvider";

const useMyFleetContext = () => {
    const context = useContext(MyFleetContext);

    if (context === null) {
        throw new Error('You forgot to use MyFleetContextProvider');
    }

    return context;
};

export default useMyFleetContext;
