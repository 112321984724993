import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TableContainer,
} from "@material-ui/core";
import { Close, EditOutlined, MoreHorizRounded } from "@material-ui/icons";
import styled from "styled-components";
import { variables } from "../../theme/variables";
import { ReactComponent as PaperPlane } from "../../assets/images/paper-plane.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import CustomInput from "../../ui/CustomInput/CustomInput";

const { colors } = variables;

export const ActionsIcon = styled(MoreHorizRounded)`
  color: ${colors.grayishBlue.hex};
`;

export const ActionsIconButton = styled(IconButton)`
  padding: 0;
`;

// Opacity is set via _dataTable.scss
export const ActionsIconHoverButton = styled(IconButton)`
  opacity: 0;
  padding: 0;
`;

export const StyledTableContainer = styled(TableContainer)`
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 0;
`;

export const CancelIcon = styled(Close)`
  color: ${colors.red.hex};
  margin-right: 0.25em;
  height: 1em;
  background-color: rgb(${colors.red.rgb}, 0.2);
  border-radius: 16px;
  padding: 5px;
`;

export const EditIcon = styled(EditOutlined)`
  color: ${colors.lightGrayishBlue.hex};
  margin-right: 0.25em;
  height: 0.75em;
`;

export const ResendInvitationIcon = styled(PaperPlane)`
  color: ${colors.lightGrayishBlue.hex};
  margin-right: 1em;
  margin-left: 0.2em;
`;

export const DeleteDelegeeMenuItem = styled(MenuItem)`
  color: #d90327;
`;

export const DeleteDelegeeIcon = styled(DeleteIcon)`
  margin-right: 1em;
  margin-left: 0.2em;
`;

export const NoPaddingBottomGrid = styled(Grid)`
  padding-bottom: 0;
`;

export const HorizontalPaddingGrid = styled(Grid)`
  padding: 0 0.5em;
`;

export const PaddingRightGrid = styled(Grid)`
  padding: 0 0.5em 0 0;
`;

export const PaddingLeftGrid = styled(Grid)`
  padding: 0 0 0 0.5em;
`;

export const TextFilterInput = styled(CustomInput)`
  width: 100%;
`;

export const FilterFormControl = styled(FormControl)`
  width: 100%;
`;

export const FilterSelect = styled(Select)`
  padding-right: 45px;
`;

export const HeaderPaddedGrid = styled(Grid)`
  padding: 0.7em 1em;
  border-bottom: 1px solid ${colors.lightGrayishBlue.hex};
  background-color: ${colors.mostlyWhite};
`;

export const StyledDataTableWrapper = styled(Grid)`
  padding: 0;
  margin: 0;
`;
