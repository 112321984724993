import React, { createContext, useReducer } from "react";
import { MyProductsContextState } from "./store/reducers/myProducts.reducer";
import myProductsContextReducer, { initialState } from "./store/reducers/myProducts.reducer";
import { myProductsContextTypes } from "./store/actions/myProducts.types";

export type MyProductsContextType = {
  myProductsState: MyProductsContextState;
  setSelectedLocation: (placeId: number) => void;
  setSorting: (sortBy: string, descending?: boolean) => void;
  resetContext: () => void;
};

export const MyProductsContext = createContext<MyProductsContextType | null>(null);

interface IMyProductsContextProps {}

const MyProductsContextProvider: React.FC<IMyProductsContextProps> = ({ children }) => {
  const [myProductsState, dispatch] = useReducer(myProductsContextReducer, initialState);

  const setSelectedLocation = (placeId: number) => {
    dispatch({
      type: myProductsContextTypes.SET_SELECTED_LOCATION,
      payload: placeId,
    });
  };

  const setSorting = (sortBy: string, descending?: boolean) => {
    dispatch({
      type: myProductsContextTypes.SET_SORTING,
      payload: { by: sortBy, descending },
    });
  };

  const resetContext = () =>
    dispatch({
      type: myProductsContextTypes.RESET_MYPRODUCTS_CONTEXT,
    });

  const store: MyProductsContextType = {
    myProductsState,
    setSelectedLocation,
    resetContext,
    setSorting,
  };

  return <MyProductsContext.Provider value={store}>{children}</MyProductsContext.Provider>;
};

export default MyProductsContextProvider;
