import { MyProductsContextAction, myProductsContextTypes } from "../actions/myProducts.types";

export type MyProductsContextDataGridState = {
  sortBy?: string;
  orderDesc?: boolean;
};

export type MyProductsContextState = {
  selectedLocation: number;
  dataTable: MyProductsContextDataGridState;
};

export const initialState: MyProductsContextState = {
  selectedLocation: 0,
  dataTable: {
    orderDesc: true,
    sortBy: "invitationDate",
  },
};

export default (
  state: MyProductsContextState = initialState,
  action: MyProductsContextAction
): MyProductsContextState => {
  switch (action.type) {
    case myProductsContextTypes.SET_SELECTED_LOCATION:
      if (state.selectedLocation === action.payload) {
        return {
          ...state,
          selectedLocation: 0,
        };
      }

      return {
        ...state,
        selectedLocation: action.payload,
      };

    case myProductsContextTypes.SET_SORTING: {
      const payloadDesc = action.payload.descending;
      const newOrderDesc =
        typeof payloadDesc === "boolean" ? payloadDesc : !state.dataTable.orderDesc;
      return {
        ...state,
        dataTable: {
          ...state.dataTable,
          sortBy: action.payload.by,
          orderDesc: newOrderDesc,
        },
      };
    }

    case myProductsContextTypes.RESET_MYPRODUCTS_CONTEXT:
      return initialState;
    default:
      return state;
  }
};
