import React, { useMemo, useRef, useState, useEffect } from "react";
import { NumberPlateModel, NumberPlateProps } from "./NumberPlate.types";
import Flag from "react-world-flags";
import { KeyboardArrowDownRounded, CheckRounded, WarningOutlined, ErrorOutline } from "@material-ui/icons";
import useOutsideClick from "../../utils/hooks/useOutsideClick";
import { findWhere, isEmpty, isUndefined } from "underscore";
import { ReactComponent as Seals } from "../../assets/images/german-license-plate-seals.svg";
import "./_numberPlate.scss";
import { MuiHelperText } from "../../components/MuiHelperText";
import { CountriesNumberPlates } from "../../lib/countriesNumberPlates";

const defaultSupportedNumberPlate : NumberPlateModel[] = new CountriesNumberPlates().getCountriesNumberPlates().map((s) => ({
  numberPlatePrefix: s.numberPlateCode,
  countryCode: s.countryCode,
}));

const NumberPlateDeSupport = React.memo((props: NumberPlateProps) => {
  const {
    id,
    name,
    placeholder,
    regionPlaceholder,
    onValueChange,
    onPrefixSelect,
    validateNumberPlate,
    prefix,
    value,
    warning,
    error,
    supportedNumberPlates = defaultSupportedNumberPlate,
    numberToSkipOnKeyPress = -1,
    setFieldValue,
    readOnly,
    compact,
  } = props;
  const [open, setOpen] = useState(false);

  const [regionCode, setRegionCode] = useState<string>("");
  const [deValue, setDeValue] = useState<string>("");

  const dropdownRef = useRef<HTMLDivElement>(null);
  const closeDropdown = () => setOpen(false);
  useOutsideClick(dropdownRef, closeDropdown);

  const openDropdown = () => {
    if (!readOnly) {
      setOpen(true);
    }
  };

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (!open ||  !dropdownRef?.current) {
        return;
      }

      const key = e.key.toUpperCase();
      const firstMatchIndex = supportedNumberPlates.findIndex(
        (c, i) => i > numberToSkipOnKeyPress &&  c.countryCode.startsWith(key)
      );

      if (firstMatchIndex !== -1) {
        const countryElements = dropdownRef.current.querySelectorAll("li.country");
        const targetElement = countryElements[firstMatchIndex];
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [open, supportedNumberPlates]);

  // Handle to and from full numberplate <=> german numberplate
  const onHandleDeNumberplateChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
    isRegion: boolean
  ) => {
    e.persist();
    e.preventDefault();

    let value = isRegion ? `${e.target.value}-${deValue}` : `${regionCode}-${e.target.value}`;

    if (value === "-") {
      value = "";
    }

    const event = {
      target: {
        value: value,
      },
    } as React.ChangeEvent<HTMLInputElement>;

    if (onValueChange) {
      onValueChange(event);
    }
  };

  const splitRegionAndNumber = () => {
    if (value?.includes("-")) {
      let numberPlateComponents = value.split("-");

      setRegionCode(numberPlateComponents[0]);
      setDeValue(numberPlateComponents[1]);
    } else {
      setDeValue(value);
    }
  };

  const parseValue = (val: string, isRegion: boolean) => {
    const splitIndex = val.indexOf("-");
    if (splitIndex < 0) {
      return isRegion ? "" : val;
    }

    const region = val.substring(0, splitIndex);
    const deVal = val.substring(splitIndex + 1);

    return isRegion ? region : deVal;
  };
  //

  // Region handlers
  const onRegionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    e.target.value = sanitize(e.target.value);
    setRegionCode(() => e.target.value);
    onHandleDeNumberplateChanged(e, true);
  };

  const onRegionBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    e.preventDefault();
    e.target.value = sanitize(e.target.value);
    setRegionCode(() => e.target.value);
    onHandleDeNumberplateChanged(e, true);
    if (validateNumberPlate) {
      validateNumberPlate(undefined);
    }
  };
  //

  // De Value handlers
  const onDeValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    e.target.value = sanitize(e.target.value);
    setDeValue(() => e.target.value);

    onHandleDeNumberplateChanged(e, false);
  };

  const onDeValueBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    e.preventDefault();
    e.target.value = sanitize(e.target.value);
    setDeValue(() => e.target.value);

    onHandleDeNumberplateChanged(e, false);

    if (validateNumberPlate) {
      validateNumberPlate(undefined);
    }
  };
  //

  // Other countries handlers
  const onValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    e.preventDefault();
    if (onValueChange) {
      onValueChange(e);
    }
  };

  const onNumberPlateBlured = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    e.preventDefault();
    e.target.value = sanitize(e.target.value);
    setDeValue(() => e.target.value);
    if (onValueChange) {
      onValueChange(e);
    }

    if (validateNumberPlate) {
      validateNumberPlate(undefined);
    }
  };
  //

  const onPrefixSelected = (np: NumberPlateModel) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    if (onPrefixSelect) {
      onPrefixSelect(np.numberPlatePrefix, np.countryCode);
    }
    closeDropdown();
  };

  const selectedCountryCode = useMemo(() => {
    const found = findWhere(supportedNumberPlates, {
      numberPlatePrefix: prefix,
    });
    if (isUndefined(found)) {
      return "";
    }

    return found.countryCode;
  }, [prefix]);

  const isGermanNumberPlate = useMemo(() => selectedCountryCode === "DE", [selectedCountryCode]);

  useEffect(() => {
    if (isUndefined(setFieldValue) || isUndefined(name)) {
      return;
    }

    if (isGermanNumberPlate) {
      if (isEmpty(regionCode) && !isEmpty(value)) {
        splitRegionAndNumber();
      }

      return;
    }

    if (value.includes("-")) {
      const valueCopy = value;
      setRegionCode("");
      setFieldValue(name, valueCopy.replace("-", ""));
    }
  }, [isGermanNumberPlate]);

  const sanitize = (val: string) => val.replaceAll(" ", "").replaceAll("-", "");


  return (
    <>
      <div className="number-plate">
        <div
          className={`number-plate-wrapper ${
            isGermanNumberPlate && !compact ? "number-plate-wrapper-flex" : ""
          } ${error ? "error" : ""} ${warning ? "warning" : ""} ${readOnly ? "readonly" : ""}`.trim()}
        >
          <div
            className={
              isGermanNumberPlate && !compact ? "prefix-selector-full prefix-selector" : "prefix-selector"
            }
          >
            <div className="prefix-selector-wrapper" onClick={openDropdown}>
              <div className="country-flag">
                <div className="flag-wrapper">
                  <Flag code={selectedCountryCode} className="flag-icon" />
                </div>
                <span className="bold">{prefix}</span>
              </div>
              {!readOnly && (
                <KeyboardArrowDownRounded
                  className="arrow-down-icon"
                  style={{
                    transform: open ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
              )}
            </div>
          </div>
          {isGermanNumberPlate ? (
            <div className="number-plate-seals-wrapper">
              <div className="number-plate-seals-region">
                <input
                  className="number-plate-regioninput"
                  type="text"
                  autoComplete="nope"
                  id="numberPlateRegion"
                  name="numberPlateRegion"
                  placeholder={regionPlaceholder}
                  value={parseValue(value, true)}
                  onChange={onRegionChange}
                  onBlur={onRegionBlur}
                  maxLength={3}
                  readOnly={readOnly}
                />
              </div>
              <div className="number-plate-seals-column">
                <Seals />
              </div>
              <div className="number-plate-seals-value">
                <input
                  className="number-plate-input"
                  type="text"
                  autoComplete="nope"
                  id={id}
                  name={name}
                  placeholder={placeholder}
                  value={parseValue(value, false)}
                  onChange={onDeValueChange}
                  onBlur={onDeValueBlur}
                  readOnly={readOnly}
                  size={parseValue(value, false).length + 1}
                />
              </div>
            </div>
          ) : (
            <input
              className="number-plate-input"
              type="text"
              autoComplete="nope"
              id={id}
              name={name}
              placeholder={placeholder}
              value={sanitize(value)}
              onChange={onValueChanged}
              onBlur={onNumberPlateBlured}
              readOnly={readOnly}
              size={value.length + 1}
            />
          )}
          {Boolean(error) && (
            <div className="error-icon-wrapper">
              <ErrorOutline className="error-icon" />
            </div>
          )}
          {Boolean(warning) && (
            <div className="warning-icon-wrapper">
              <WarningOutlined className="warning-icon" />
            </div>
          )}
        </div>
        <div
          ref={dropdownRef}
          className="countries-dropdown"
          style={{
            display: open ? "block" : "none",
          }}
        >
          <ul className="countries-dropdown-list">
            {supportedNumberPlates.map((c, i) => (
              <li
                key={c.countryCode + i}
                className="country"
                value={c.countryCode}
                onClick={onPrefixSelected({
                  countryCode: c.countryCode,
                  numberPlatePrefix: c.numberPlatePrefix,
                })}
              >
                <div className="country-flag-wrapper">
                  <Flag code={c.countryCode} className="flag-icon" />
                  <span className="bold">{c.numberPlatePrefix}</span>
                </div>
                <CheckRounded
                  className="check-icon"
                  style={{
                    display: prefix === c.numberPlatePrefix ? "block" : "none",
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <MuiHelperText error={error ?? warning} className={error ? "" : "text-warning"}/>
    </>
  );
});

export default NumberPlateDeSupport;
