export const myProductsContextTypes = {
  SET_SELECTED_LOCATION: "context/SET_SELECTED_LOCATION",
  SET_SORTING: "context/SET_SORTING",
  RESET_MYPRODUCTS_CONTEXT: "context/RESET_MYPRODUCTS_CONTEXT",
} as const;

export type MyProductsContextAction =
  | { type: typeof myProductsContextTypes.SET_SELECTED_LOCATION, payload: number }
  | { type: typeof myProductsContextTypes.SET_SORTING, payload: { by: string; descending?: boolean } }
  | { type: typeof myProductsContextTypes.RESET_MYPRODUCTS_CONTEXT }
